<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" class="text-center">
        <div class="title">Welcome to {{tournament.name}}</div>
        <div class="subtitle">{{division.name}}</div>
      </v-col>
      <v-col cols="12" v-for="tab in divisionTabs" :key="tab.to">
        <v-btn
          block rounded
          color="color3 color3Text--text"
          large class="pa-12"
          :to="tab.to">
          {{tab.text}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [RouteMixin],
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'view']),
    division () {
      return this.getDivision(this.divisionId)
    },
    isTournament () {
      return this.tournament.isTournament && this.division.isTournament
    },
    isLeague () {
      return this.tournament.isLeague
    },
    isCoaches () {
      return this.division && this.division.isCoaches
    },
    divisionTabs () {
      const tabs = []

      tabs.push(
        {
          text: `View The ${this.division.acceptanceOnly ? 'Accepted ' : ''}${this.division.teamLabel}`,
          to: `/tournament/${this.tournamentId}/division/${this.divisionId}/teams`,
          count: this.division.teamCount
        }
      )
      if (this.division.waitlistTeams.length && !this.isCoaches && (this.view.admin || this.division.showTeams) && !(this.view.public && this.division.hideWaitlist)) {
        tabs.push(
          {
            text: `View The ${this.division.acceptanceOnly ? 'Pending Teams' : 'Waitlist'}`,
            to: `/tournament/${this.tournamentId}/division/${this.divisionId}/waitlist`,
            count: this.division.waitlistTeams.length
          }
        )
      }
      if ((this.isTournament || this.isLeague) && !this.isCoaches) {
        const stand = this.division.standings
        stand && stand.filter(f => (this.view.admin) || (this.view.public && f.published)).forEach((day, i) => {
          tabs.push({
            text: `Jump to the ${day.title}`,
            to: `/tournament/${this.tournamentId}/division/${this.divisionId}/standings/${i}`,
            icon: day.locked ? 'fas fa-lock' : null
          })
        })
        if (this.isTournament) {
          this.division.days.filter(f => (this.view.admin) || (this.view.public && f.published)).forEach(day => {
            if (day.poolPlay) {
              const poolId = day.flight && day.flight.pools && day.flight.pools.length === 1 && day.flight.pools[0].id
              const addOn = poolId ? `/${poolId}` : ''
              tabs.push({
                text: `Jump to the ${day.name}`,
                to: `/tournament/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/pools${addOn}`,
                count: day.pools.length,
                icon: day.locked ? 'fas fa-lock' : null
              })
            } else {
              tabs.push({
                text: `Jump to the ${day.name}`,
                to: `/tournament/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/brackets`,
                icon: day.locked ? 'fas fa-lock' : null
              })
            }
          })
        }
        if (this.isLeague) {
          tabs.push({
            text: 'Jump to Regular Season',
            to: `/league/${this.tournamentId}/division/${this.divisionId}/regular_season`
          })
          this.division.days.filter(f => (this.view.admin) || (this.view.public && f.published)).forEach(day => {
            if (!day.poolPlay) {
              tabs.push({
                text: `Jump to the ${day.name}`,
                to: `/league/${this.tournamentId}/division/${this.divisionId}/round/${day.id}/brackets`,
                icon: day.locked ? 'fas fa-lock' : null
              })
            }
          })
        }
      }
      return tabs
    }
  }
}
</script>
